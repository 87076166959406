import React, { useState } from "react";
import { MdFileUpload } from "react-icons/md";
import * as XLSX from "xlsx";

export const ImportCSVModal = ({ onDataRecive, getNewObjectArray }) => {
  const handelCsvImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };
  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    onDataRecive(list);
  };
  return (
    <label
      style={{
        background: "#6c757d",
        color: "white",
        marginRight: "6px",
        padding: "6px 10px",
        maxHeight: '40px',
        borderRadius: "6px",
        cursor: "pointer",
      }}
    >
      <MdFileUpload size="20px" />
      Upload CSV
      <input
        style={{ display: "none", cursor: "pointer" }}
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handelCsvImport}
      />
    </label>
  );
};
