import React, { Component } from "react";
import { GoSearch } from "react-icons/go";
import { Link } from "react-router-dom";
import { FaAngleRight, FaHome } from "react-icons/fa";
import "./Header.css";
import { MdCreateNewFolder, MdFileUpload } from "react-icons/md";
import { Dropbox } from "dropbox";
import { token$ } from "../store";
import Create from "../Modals/Create";
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choosedFiles: [],
      path: "",
      showCreateModal: false,
    };
  }
  handleCreateModal = (status) => {
    this.setState({ showCreateModal: status });
  };
  uploadFiles = (e) => {
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
    let dropBox = new Dropbox({ fetch: fetch, accessToken: token$.value });
    let files = Array.from(e.target.files);

    if (files.length === 0) {
      return;
    }
    this.props.updateChoosenFiles({ files });

    if (files.some((file) => file.size > UPLOAD_FILE_SIZE_LIMIT)) {
      alert("One of the files is too big!");
    } else {
      const root = this.props.location.pathname.slice(5);
      const promises = files.map((file) =>
        dropBox.filesUpload({
          path: root + "/" + file.name,
          contents: file,
        })
      );

      Promise.all(promises)
        .then((responses) => {
          console.log("promiseAll response", responses);
          const files = responses.map((response) => ({
            ...response,
            ".tag": "file",
          }));
          console.log("RENDER from SIDEBAR");
          const newDocuments = [...this.props.documents, ...files];
          this.props.updateDocs(newDocuments);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  render() {
    const { logOut, location, search, filterSearch, userName } = this.props;
    const parts = location.pathname.substring(6).split("/");
    let links;
    if (parts[0] !== "") {
      links = parts.map((_, idx) => {
        //console.log("idx", parts.slice(0, idx + 1))
        return "/home/" + parts.slice(0, idx + 1).join("/");
      });
      parts.unshift("Home");
      links.unshift("/home");
    } else {
      parts[0] = "Home";
      links = ["/home"];
    }
    return (
      <>
        <div className="header-container">
          <div className="logo-search-container">
            <div className="logo-container">
              Logo
              {/* <img src={logo} alt="dropbox logo uploading files" className="logo-header" /> */}
            </div>
            <div className="search-box">
              <div
                className="search-field"
                style={{ border: "1px solid #ddd" }}
              >
                <GoSearch
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "15px",
                    fontSize: "1.2em",
                    color: "#106BAC",
                  }}
                />
                <input
                  className="search-input"
                  style={{ border: "none" }}
                  type="text"
                  placeholder="Search folder"
                  name="search"
                  id="search"
                  onChange={filterSearch}
                  value={search}
                />
              </div>
            </div>
            <div className="username-logout">
              <p className="username">
                {userName}
                <span className="username-span">|</span>
              </p>
              <button className="logout-button" onClick={logOut}>
                Log out
              </button>
            </div>
          </div>

          <div className="path-logout-container">
            <div className="header-path">
              {/*each part of the path should be clickable*/}
              <nav>
                {parts.map((part, idx) => {
                  return (
                    <div className="paths" key={idx}>
                      {idx === 0 ? (
                        <span>
                          <FaHome
                            style={{
                              position: "relative",
                              marginRight: "5px",
                            }}
                          />
                        </span>
                      ) : null}
                      <Link to={links[idx]} className="pathLink">
                        {part}
                      </Link>
                      {idx !== parts.length - 1 ? (
                        <span className="divider">
                          <FaAngleRight />
                        </span>
                      ) : null}
                    </div>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Header;
