/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFolder, FaFile, FaFilePdf } from "react-icons/fa";
import "./FileList.css";
import { convertDate } from "../convertDate.js";
import { convertBytes } from "../convertBytes.js";
import { Dropbox } from "dropbox";
const FileList = ({ doc, getLinkToFile, localToken }) => {
  const [thumbnailUrl, updateThumbnailUrl] = useState(null);

  useEffect(() => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    console.log("TRY TO FETCH THUMBNAIL");
    if (
      doc.name.slice(doc.name.length - 3) === "jpg" ||
      doc.name.slice(doc.name.length - 4) === "jpeg" ||
      doc.name.slice(doc.name.length - 3) === "png"
    ) {
      dropbox
        .filesGetThumbnail({
          path: doc.path_lower,
          size: "w32h32",
        })
        .then((response) => {
          if (response.fileBlob) {
            const url = URL.createObjectURL(response.fileBlob);
            updateThumbnailUrl(url);
          }
          console.log("RENDER from THUMBNAIL");
        })
        .catch(function (error) {
          console.log(error, "Error by creating thumbnail");
        });
    }
  }, [doc.name, updateThumbnailUrl, doc.path_lower, localToken]);
  
  if (doc) {
    return (
      <li className="file-item">
        <div className="item-SmlCtn">
          {doc[".tag"] === "file" ? (
            <>
              {doc.name.slice(doc.name.length - 3) === "pdf" ? (
                <FaFilePdf
                  size="2rem"
                  style={{ color: "rgb(34, 130, 208)", marginRight: "10px" }}
                />
              ) : thumbnailUrl ? (
                <img
                  src={thumbnailUrl}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
              ) : (
                <FaFile size="2rem" className="folder-Icon" />
              )}
              <a
                className="document-Link"
                onClick={() => getLinkToFile(doc.path_lower)}
              >
                {doc.name}
              </a>
            </>
          ) : (
            <>
              <FaFolder size="2rem" className="folder-Icon" />
              <Link to={"/home" + doc.path_lower} className="document-Link">
                {doc.name}
              </Link>
            </>
          )}
        </div>
        <p className="metaData">
          {doc[".tag"] === "file" ? convertBytes(doc.size) : "--"}
        </p>
        <p className="modified">{convertDate(doc.client_modified)}</p>
      </li>
    );
  }
};

export default FileList;
