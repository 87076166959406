import { Dropbox } from "dropbox";
import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaPlayCircle } from "react-icons/fa";
import ReactPlayer from "react-player";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class DetailModal extends React.Component {
  state = {
    videLink: "",
    thumbnail: "",
  };
  componentDidMount() {
    const { modalData, accessToken } = this.props;
    let dropbox = new Dropbox({
      fetch: fetch,
      accessToken: accessToken,
    });
    if (
      (modalData &&
        modalData.name.slice(this.props.modalData.name.length - 3) === "mov") ||
      (modalData &&
        modalData.name.slice(this.props.modalData.name.length - 3) === "mp4") ||
      (modalData &&
        modalData.name.slice(this.props.modalData.name.length - 3) === "3gp") ||
      (modalData &&
        modalData.name.slice(this.props.modalData.name.length - 3) === "flv") ||
      (modalData &&
        modalData.name.slice(this.props.modalData.name.length - 3) === "mkv")
    ) {
      dropbox
        .filesGetThumbnail({
          path: this.props.modalData.path_lower,
          size: "w2048h1536",
        })
        .then((response) => {
          if (response.fileBlob) {
            const url = URL.createObjectURL(response.fileBlob);
            this.setState({ thumbnail: url });
          }
        })
        .catch(function (error) {
          console.log(error, "Error by creating thumbnail");
        });
    }
  }
  getStreamAbleLink = (path) => {
    let dropbox = new Dropbox({ accessToken: this.props.accessToken });
    dropbox
      .filesGetTemporaryLink({ path: path })
      .then((response) => {
        if (response) {
          this.setState({
            videLink: response.link,
          });
        }
      })
      .catch((error) => {
        console.error(error, "Error by downloading file");
      });
  };
  getPlayIcon = () => {
    return (
      <>
        <FaPlayCircle
          onClick={() =>
            this.getStreamAbleLink(this.props.modalData.path_lower)
          }
          style={{ position: "absolute", top: "44%", left: "50%", cursor:'pointer' }}
          size={50}
          color="#ff2400"
        />
        <img
          width={"100%"}
          style={{ maxHeight: "400px", objectFit: "cover" }}
          src={this.props.thumbnailUrl}
          alt=""
        />
      </>
    );
  };
  render() {
    let element = this.getPlayIcon();
    const { videLink } = this.state;
    return (
      <div>
        <Modal
          centered
          size="lg"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={this.props.className}
        >
          <ModalBody style={{ minHeight: 400 }}>
            <AiFillCloseCircle
              onClick={this.props.toggle}
              size={25}
              style={{
                position: "absolute",
                top: "8%",
                zIndex: 5,
                right: "5%",
                color:'white',
                cursor:'pointer',
                borderRadius:'50%',
                border:'red 2px solid',
                background:'red'
                
              }}
            />
            <h5
              style={{
                position: "absolute",
                fontFamily: "initial",
                color: "black",
              }}
            >
              {this.props.modalData.name}
            </h5>
            {videLink !== "" ? (
              <ReactPlayer width={"100%"} controls={true} url={videLink} />
            ) : (
              element
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default DetailModal;
