import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import VideoListing from "../Files/VideoListing/VideoListing";
import GridList from "../Files/GridList/GridList";
import FileList from "../Files/List/FileList";
import "./Main.css";
import { MdError, MdList } from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { IoMdList } from "react-icons/io";
import { BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { Dropbox } from "dropbox";
import { updateToken } from "../store";
import { Button, Popover, PopoverBody } from "reactstrap";
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineMenuUnfold,
  AiOutlineMenuFold,
} from "react-icons/ai";
import Sidebar from "../Sidebar/Sidebar";
import {
  countWords,
  MA_Title_List,
  MA_Description_List,
  TagsList
} from "../Files/VideoListing/const";
import { ReactCsv } from "../Files/ExportCSV";
import { ImportCSVModal } from "../Modals/ImportCSVModal";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { sort_by,swapElements } from "../common/_commonfunctions";

const Main = ({ localToken, documents, updateDocs, location, search }) => {
  const [tab, updateTab] = useState("name");
  const [errorStatus, updateErrorStatus] = useState(false);
  const [popoverOpen, setPopOverOpen] = useState(false);
  const [choosenFiles, updateChoosenFiles] = useState([]);
  const [dropDown, updateDropDown] = useState(false);
  const [keywordId, updateKeyWordId] = useState(0);
  const [currentCursor, updateCurrentCursor] = useState({});
  const nodeDropdown = useRef();
  const nodeGridIcon = useRef();
  const [orderType, setSortValue] = useState("desc");
  const pageLimit = parseInt(process.env.REACT_APP_RECORD_PER_PAGE);
  const orderBy = localStorage.getItem("sort_by");
  const [order, setOrder] = useState(orderBy);
  const loadFiles = useCallback(() => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    //let dropbox = new Dropbox({ accessToken: localToken })
    if (location.pathname === "/home" && search === "") {
      dropbox
        .filesListFolder({ path: "", limit: pageLimit })
        .then((response) => {
          getNewObjectArray(response.entries);
          updateCurrentCursor({
            cursor: response.cursor,
            has_more: response.has_more,
          });
          // updateDocs(response.entries); // update in state
          updateErrorStatus(false);
          updateTab("name");
        })
        .catch(() => {
          updateErrorStatus(true);
        });
    } else if (location.pathname === "/home" && search.length > 1) {
      dropbox
        .filesSearch({
          query: search,
          path: "",
        })
        .then((response) => {
          let newArray = [];
          if (response.matches.length > 0) {
            response.matches.map((item) => newArray.push(item.metadata));
          }
          getNewObjectArray(newArray);
          // updateDocs(newArray); // update in state
          updateErrorStatus(false);
          updateTab("name");
        })
        .catch((response) => {
          updateErrorStatus(true);
        });
    } else {
      let newPath = location.pathname.slice(5);
      if (location.pathname !== "/" && search.length > 1) {
        dropbox
          .filesSearch({
            query: search,
            path: newPath,
          })
          .then((response) => {
            let newArray = [];
            if (response.matches.length > 0) {
              response.matches.map((item) => newArray.push(item.metadata));
            }
            // updateDocs(newArray);
            getNewObjectArray(newArray);
            updateErrorStatus(false);
            updateTab("name");
          })
          .catch((response) => {
            updateErrorStatus(true);
          });
      } else {
        dropbox
          .filesListFolder({ path: newPath, limit: pageLimit })
          .then((response) => {
            getNewObjectArray(response.entries);
            updateCurrentCursor({
              cursor: response.cursor,
              has_more: response.has_more,
            });
            // updateDocs(response.entries);
            updateErrorStatus(false);
            updateTab("name");
          })
          .catch((response) => {
            updateErrorStatus(true);
          });
      }
    }
  }, [location.pathname, localToken, updateDocs, search]);

  useEffect(() => {
    loadFiles();
  }, [loadFiles, search]);
  const showDropDown = useCallback(() => {
    updateDropDown(dropDown ? false : true);
    // setPopOverOpen(false);
  }, [dropDown]);

  const showGridDropDown = useCallback(() => {
    setPopOverOpen(popoverOpen ? false : true);
  }, [popoverOpen]);
  const handleClickOutside = useCallback(
    (e) => {
      if (nodeDropdown.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      showDropDown(dropDown);
    },
    [showDropDown, dropDown]
  );
  const handleClickOutsideGrid = useCallback(
    (e) => {
      if (nodeGridIcon.current.contains(e.target)) {
        return;
      }
      // outside click
      console.log("hit outside grid");

      showGridDropDown(popoverOpen);
    },
    [showGridDropDown, popoverOpen]
  );
  useEffect(() => {
    //this document.addEventListerner can only be used inside a useEffect
    if (dropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDown, handleClickOutside]);

  useEffect(() => {
    //this document.addEventListerner can only be used inside a useEffect
    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutsideGrid);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideGrid);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideGrid);
    };
  }, [popoverOpen, handleClickOutsideGrid]);
  const longpoll = useCallback(() => {
    let dbx = new Dropbox({ fetch: fetch, accessToken: localToken });

    let originalPath = location.pathname;

    dbx
      .filesListFolderGetLatestCursor({
        path: "",
        recursive: true,
        include_media_info: false,
        include_deleted: false,
        include_has_explicit_shared_members: false,
      })
      .then((res) => {
        updateLongPoll(res.cursor);
      })
      .catch((err) => {
        console.log(err);
      });
    const updateLongPoll = (cursor) => {
      dbx
        .filesListFolderLongpoll({ cursor: cursor, timeout: 30 })
        .then((response) => {
          if (response.changes) {
            if (originalPath === window.location.pathname) {
              loadFiles();
            }
          } else {
            return;
          }

          longpoll();
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, [loadFiles, localToken, location.pathname]);

  useEffect(() => {
    longpoll();
    console.log("long");
  }, [longpoll]);

  const showTab = (tabName) => {
    updateTab(tabName);
  };

  let tabActiveStyle = {
    backgroundColor: "#F7F7F7",
    color: "rgb(34, 138, 208)",
  };
  const handelPopOver = () => {
    setPopOverOpen(!popoverOpen);
    updateDropDown(false);
  };
  const getLinkToFile = (path) => {
    let dropbox = new Dropbox({ accessToken: localToken });
    dropbox
      .filesGetTemporaryLink({ path: path })
      .then((response) => {
        window.location.href = response.link;
      })
      .catch((error) => {
        console.error(error, "Error by downloading file");
      });
  };
  const getStreamAbleLink = (path) => {
    let dropbox = new Dropbox({ accessToken: localToken });
    dropbox
      .filesGetTemporaryLink({ path: path })
      .then((response) => {
        let newArray = null;
        newArray = documents.map((element) =>
          element.path_lower === path
            ? { ...element, streamLink: response.link }
            : element
        );
        updateDocs(newArray);
        // getNewObjectArray(newArray);
      })
      .catch((error) => {
        console.error(error, "Error by downloading file");
      });
  };
  const validateField = ({ name, value, id }) => {
    let updatedList = documents.map((item) => {
      if (item.id === id) {
        let totalChar = value.trim().length;
        let totalPhrases = countWords(value);
        return {
          ...item,
          [name]: value,
          [name + "_char"]: totalChar,
          [name + "_phar"]: totalPhrases,
        };
      }
      return item;
    });
    updateDocs(updatedList);
  };
  const removeObjectWithId = (arr, id) => {
    const arrCopy = Array.from(arr);
    arrCopy.splice(id, 1);
    return arrCopy;
  };
  const onFormFieldChange = (id) => (e) => {
    const { name, value } = e.target;
    if (
      name === "filename" ||
      "description" ||
      name === "title" ||
      "ma_description"
    ) {
      validateField({ name, value, id });
      return;
    }
  };
  const getNewObjectArray = (Results) => {
    for (const element of Results) {
      if (element[".tag"] === "file") {
        element.filename = element.name;
        element.title = "";
        element.description = "";
        element.ma_description = MA_Description_List
        element.ma_title = MA_Title_List;
        element.keywords = TagsList;
      }
    }
    updateDocs(Results);
  };
  const onNextPage = () => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    dropbox
      .filesListFolderContinue({ cursor: currentCursor.cursor })
      .then((response) => {
        getNewObjectArray(response.entries);
        updateCurrentCursor({
          cursor: response.cursor,
          has_more: response.has_more,
        });
      });
  };
  const handleGetCSVData = (data) => {
    let newArray = documents.map((element) => {
      for (var i in data) {
        if (data[i].id === element.id) {
          //TITLE
          element.title = data[i].TITLE;
          element.title_phar = countWords(data[i].TITLE);
          element.title_char = data[i].TITLE.trim().length;
          //DESCRIPTION
          element.description = data[i].DESCRIPTION;
          element.description_char = data[i].DESCRIPTION.trim().length;
          element.description_phar = countWords(data[i].DESCRIPTION);
          //MA_TITLE
          // KEYWORDS
          let keywordArrayTitle = [];
          keywordArrayTitle = data[i].MA_TITLE.split(",");
          element.ma_title = keywordArrayTitle;

          let keywordArrayDescription = [];
          keywordArrayDescription = data[i].MA_DESCRIPTION.split(",");
          element.ma_description = keywordArrayDescription;
          // KEYWORDS
          let keywordArray = [];
          keywordArray = data[i].KEYWORDS.split(",");
          element.keywords = keywordArray;
          break; //Stop this loop, we found it!
        }
      }
      return element;
    });
    updateDocs(newArray);
  }; // Function to update list on drop
  const handleDropStart = (id) => {
    updateKeyWordId(id);
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // if(!oldIndex || !newIndex) return;
    let objResults = documents.map((main) => {
      if (main.id === keywordId) {
        let arrayCopy = main.keywords;
        var updatedList = [...arrayCopy];
        swapElements(updatedList, oldIndex, newIndex);
      } else {
        updatedList = main.keywords;
      }
      return { ...main, keywords: updatedList };
    });
    updateDocs(objResults);
  };
  const onKeywordAdd = (value, id) => {
    if (!value) return;
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.keywords;
        newArray = arrayCopy.concat([value]);
      } else {
        newArray = main.keywords;
      }
      return { ...main, keywords: newArray };
    });
    updateDocs(objResults);
  };
  const onKeywordAddTitle = (value, id) => {
    if (!value) return;
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.ma_title;
        newArray = arrayCopy.concat([value]);
      } else {
        newArray = main.ma_title;
      }
      return { ...main, ma_title: newArray };
    });
    updateDocs(objResults);
  };
  const onKeywordAddDescription = (value, id) => {
    if (!value) return;
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.ma_description;
        newArray = arrayCopy.concat([value]);
      } else {
        newArray = main.ma_description;
      }
      return { ...main, ma_description: newArray };
    });
    updateDocs(objResults);
  };

  const onRemoveTag = (e, id) => {
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.keywords;
        let objWithIdIndex = arrayCopy.findIndex((obj) => obj === e);
        newArray = removeObjectWithId(arrayCopy, objWithIdIndex);
      } else {
        newArray = main.keywords;
      }
      return { ...main, keywords: newArray };
    });
    updateDocs(objResults);
  };
  const onRemoveTagTitle = (e, id) => {
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.ma_title;
        let objWithIdIndex = arrayCopy.findIndex((obj) => obj === e);
        newArray = removeObjectWithId(arrayCopy, objWithIdIndex);
      } else {
        newArray = main.ma_title;
      }
      return { ...main, ma_title: newArray };
    });
    updateDocs(objResults);
  };
  const onRemoveTagDescription = (e, id) => {
    let objResults = documents.map((main) => {
      let newArray;
      if (main.id === id) {
        let arrayCopy = main.ma_description;
        let objWithIdIndex = arrayCopy.findIndex((obj) => obj === e);
        newArray = removeObjectWithId(arrayCopy, objWithIdIndex);
      } else {
        newArray = main.ma_description;
      }
      return { ...main, ma_description: newArray };
    });
    updateDocs(objResults);
  };
  const onBackToLogin = () => {
    updateToken(null);
  };
  const setSorting = (order) => {
    localStorage.setItem("sort_by", order);
    setOrder(order);
  };
  if (errorStatus) {
    return ReactDOM.createPortal(
      <div className="modalContainer">
        <div className="modalBox">
          <div className="modalHeadline">
            <MdError
              size="25px"
              style={{
                position: "relative",
                top: "-3px",
                color: "red",
                marginRight: "3px",
              }}
            />
            <h5>Error!</h5>
          </div>
          <div className="errorMessages">
            <p>
              The requested session is expired or folder path does not exist.
            </p>
          </div>
          <Button
            onClick={onBackToLogin}
            outline
            size="xl"
            className="back-login"
            to="/home"
          >
            Back to Login
          </Button>
        </div>
      </div>,
      document.body
    );
  }

  const onSort = (value) => {
    if (value === "asc") {
      setSortValue("des");
      documents.sort(sort_by("name", false, (a) => a));
    } else {
      setSortValue("asc");
      documents.sort(sort_by("name", true, (a) => a));
    }
  };
  let arrayPrint;

  if (tab === "name") {
    if (orderBy === "video-list") {
      arrayPrint = documents.map((doc) => {
        return (
          <VideoListing
            key={doc.id}
            doc={doc}
            onRemoveTag={onRemoveTag}
            onRemoveTagTitle={onRemoveTagTitle}
            onRemoveTagDescription={onRemoveTagDescription}
            onKeywordAdd={onKeywordAdd}
            onKeywordAddTitle={onKeywordAddTitle}
            onKeywordAddDescription={onKeywordAddDescription}
            getStreamAbleLink={getStreamAbleLink}
            location={location}
            onFormFieldChange={onFormFieldChange}
            localToken={localToken}
            onSortStart={handleDropStart}
            onSortEnd={onSortEnd}
          />
        );
      });
    } else if (orderBy === "grid") {
      arrayPrint = documents.map((doc, index) => {
        return (
          <GridList
            key={index}
            doc={doc}
            getLinkToFile={getLinkToFile}
            location={location}
            documents={documents}
            updateDocs={updateDocs}
            localToken={localToken}
          />
        );
      });
    } else if (orderBy === "list") {
      arrayPrint = documents.map((doc) => {
        return (
          <FileList
            key={doc.id}
            doc={doc}
            getLinkToFile={getLinkToFile}
            location={location}
            documents={documents}
            updateDocs={updateDocs}
            localToken={localToken}
          />
        );
      });
    }
  }
  return (
    <>
      <main>
        <div className="titleBar">
          <div className="tabsCtn">
            <div
              className="tabs"
              style={tab === "name" ? tabActiveStyle : {}}
              onClick={() => showTab("name")}
            >
              <p>All Files</p>
            </div>
          </div>
          {orderBy === "list" ? (
            <div className="tagCtn">
              {/* <p className="metaTag">Size</p>
              <p className="modifiedTag">Modified</p> */}
            </div>
          ) : null}
          <div className="grid-icon">
            {order === "video-list" ? (
              <>
                <ImportCSVModal
                  onDataRecive={(list) => handleGetCSVData(list)}
                />
                <ReactCsv documents={documents} />
              </>
            ) : null}
            <div
              style={{
                background: "#f1f1f1",
                color: "white",
                marginRight: "6px",
                padding: "2px 7px",
                maxHeight: "40px",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              {orderType === "asc" ? (
                <HiSortAscending
                  onClick={() => onSort("asc")}
                  size={40}
                  color="gray"
                />
              ) : (
                <HiSortDescending
                  onClick={() => onSort("desc")}
                  size={40}
                  color="gray"
                />
              )}
            </div>

            <div
              style={{
                backgroundColor: "#f1f1f1",
                borderRadius: "3px",
                padding: "6px 8px",
                zIndex: 15,
                maxHeight: 40,
              }}
            >
              <CgMenuGridR
                id="Popover1"
                size={26}
                color="gray"
                onClick={() => handelPopOver()}
              />
              {popoverOpen ? (
                <AiOutlineUp
                  onClick={() => handelPopOver()}
                  color="gray"
                  id="Popover1"
                  size={17}
                />
              ) : (
                <AiOutlineDown
                  onClick={() => handelPopOver()}
                  color="gray"
                  id="Popover1"
                  size={17}
                />
              )}

              <Popover
                placement="bottom"
                isOpen={popoverOpen}
                className="popover"
                target="Popover1"
                toggle={() => handelPopOver()}
                hideArrow={true}
              >
                <div ref={nodeGridIcon}>
                  <PopoverBody className="popover">
                    <div className="popover-grid">
                      <span onClick={() => setSorting("grid")}>
                        {" "}
                        <CgMenuGridR className="m-1" size={20} />
                        Grid
                      </span>
                      <span onClick={() => setSorting("list")}>
                        {" "}
                        <IoMdList className="m-1" size={20} />
                        List
                      </span>
                      <span onClick={() => setSorting("video-list")}>
                        {" "}
                        <MdList className="m-1" size={20} />
                        Video List
                      </span>
                    </div>
                  </PopoverBody>
                </div>
              </Popover>
            </div>
            {/* <div className="sidebar-dropdown" ref={nodeDropdown}>
              <button onClick={showDropDown}>
                {dropDown ? (
                  <AiOutlineMenuUnfold color="gray" size="35px" />
                ) : (
                  <AiOutlineMenuFold color="gray" size="35px" />
                )}
              </button>
              <div className={dropDown ? "sb-dropdown active" : "sb-dropdown"}>
                <Sidebar
                  localToken={localToken}
                  documents={documents}
                  updateDocs={updateDocs}
                  choosenFiles={choosenFiles}
                  updateChoosenFiles={updateChoosenFiles}
                  location={location}
                />
              </div>
            </div> */}
          </div>
        </div>
        <ul className={`${orderBy !== "list" ? "grid-section" : null}`}>
          {arrayPrint}
        </ul>
      </main>
      {currentCursor.has_more === true ? (
        <BsArrowRightCircle
          style={{
            position: "absolute",
            cursor: "pointer",
            right: 0,
            bottom: -30,
          }}
          color="gray"
          size={32}
          onClick={onNextPage}
        ></BsArrowRightCircle>
      ) : (
        <AiOutlineHome
          style={{
            position: "absolute",
            cursor: "pointer",
            right: 0,
            bottom: -32,
          }}
          color="gray"
          size={32}
          onClick={() => loadFiles()}
        ></AiOutlineHome>
      )}
    </>
  );
};

export default Main;
