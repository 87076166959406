import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { Button } from "reactstrap";

export const ReactCsv = (props) => {
  const Results = [];
  for (const element of props.documents) {
    if (element[".tag"] === "file") {
      Results.push(element);
    }
  }

  const currentdate = new Date();
  const createCsvFileName = () =>
    `data_${
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes()
    }.csv`;
  const headers = [
    { label: "id", key: "id" },
    { label: "FILENAME", key: "name" },
    { label: "TITLE", key: "title" },
    { label: "MA_TITLE", key: "ma_title" },
    { label: "DESCRIPTION", key: "description" },
    { label: "MA_DESCRIPTION", key: "ma_description" },
    { label: "KEYWORDS", key: "keywords" },
  ];

  let data = [];
  Results.forEach((item) => {
    data.push({
      id: item.id,
      name: item.name,
      title: item.title,
      ma_title: item.ma_title.join(', '),
      description: item.description,
      ma_description: item.ma_description,
      keywords: item.keywords.join(', ')
    });
  });

  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={createCsvFileName()}
      target="_blank"
      style={{
        paddingRight: 15,
      }}
    >
      <Button
        style={{ maxHeight: "40px" }}
        variant="contained"
        color="secondary"
      >
        <MdFileDownload size="20px" /> Download CSV
      </Button>
    </CSVLink>
  );
};
