import React, { useEffect, useState } from "react";
import * as queryString from "query-string";
import "./LogIn.css";
import { Helmet } from "react-helmet";
import tokenUrl from "../getToken";
import { token$, updateToken } from "../store";
import { Redirect } from "react-router-dom";

// return tokenSaved ? <Redirect to="/" /> : null;
const LogIn = () => {
  const token = localStorage.getItem("token");
  const accessToken = queryString.parse(window.location.hash).access_token;
  useEffect(() => {
    // for the user to get to thier own dropBox

    if (accessToken !== undefined) {
      updateToken(accessToken);
    }
  }, [accessToken]);
  if (accessToken || token) {
    return <Redirect to="/home" />;
  } else {
    return (
      <div className="container-login">
        <Helmet>
          <title>KW@stockagent - Login</title>
        </Helmet>
        <h1>Connection with dropdox </h1>
        <a href={tokenUrl}>connect</a>
      </div>
    );
  }
};

export default LogIn;
