import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import {
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import { FaFile, FaFilePdf, FaPlayCircle } from "react-icons/fa";
import "./VideoListing.css";
import { Dropbox } from "dropbox";
import { AiOutlineClose } from "react-icons/ai";
import ReactPlayer from "react-player/lazy";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import close from "../../assets/images/cancel.png";
import { countWords } from "./const";
const VideoListing = ({
  doc,
  getStreamAbleLink,
  localToken,
  onFormFieldChange,
  onRemoveTag,
  onRemoveTagTitle,
  onRemoveTagDescription,
  onKeywordAdd,
  onKeywordAddTitle,
  onKeywordAddDescription,
  onSortEnd,
  onSortStart,
}) => {
  const [dropDown, updateDropDown] = useState(false);
  const [thumbnailUrl, updateThumbnailUrl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTitle, setInputValueTitle] = useState("");
  const [inputValueDescription, setInputValueDescription] = useState("");

  const {
    title,
    title_char,
    title_phar,
    description,
    description_phar,
    description_char,
    ma_description,
    ma_description_char,
    ma_description_phar,
  } = doc;
  const nodeDropdown = useRef();
  const showDropDown = useCallback(() => {
    updateDropDown(dropDown ? false : true);
  }, [dropDown]);
  const SortableListItem = SortableElement(({ item }) => {
    let count = countWords(item);
    return (
      item.length > 0 ? (
      <li className="list-group-item">
     
          <Badge
            name="keywords"
            color={
              count === 1 ? "primary" : count === 2 ? "success" : "secondary"
            }
            className="m-1 p-2"
          >
            {" "}
            {item}{" "}
            <img
              height={20}
              src={close}
              id="button_value"
              name={doc.id}
              className={item}
              style={{ cursor: "pointer", padding: "2px", color: "white" }}
              alt=""
            />
          </Badge>
    
      </li>
      ) : (
      ""
      )
    );
  });
  const handleClickOutside = useCallback(
    (e) => {
      if (nodeDropdown.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      showDropDown(dropDown);
    },
    [showDropDown, dropDown]
  );

  useEffect(() => {
    //this document.addEventListerner can only be used inside a useEffect
    if (dropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDown, handleClickOutside, onFormFieldChange]);

  useEffect(() => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    console.log("TRY TO FETCH THUMBNAIL");
    if (
      doc.name.slice(doc.name.length - 3) === "jpg" ||
      doc.name.slice(doc.name.length - 4) === "jpeg" ||
      doc.name.slice(doc.name.length - 3) === "png" ||
      doc.name.slice(doc.name.length - 3) === "mkv" ||
      doc.name.slice(doc.name.length - 3) === "mp4" ||
      doc.name.slice(doc.name.length - 3) === "mov" ||
      doc.name.slice(doc.name.length - 3) === "3gp" ||
      doc.name.slice(doc.name.length - 3) === "flv"
    ) {
      dropbox
        .filesGetThumbnail({
          path: doc.path_lower,
          size: "w2048h1536",
        })
        .then((response) => {
          if (response.fileBlob) {
            const url = URL.createObjectURL(response.fileBlob);
            updateThumbnailUrl(url);
          }
          // console.log("RENDER from THUMBNAIL");
        })
        .catch(function (error) {
          console.log(error, "Error by creating thumbnail");
        });
    }
  }, [doc.name, updateThumbnailUrl, doc.path_lower, localToken]);

  const getPlayIcon = () => {
    if (
      doc.name.slice(doc.name.length - 3) === "mkv" ||
      doc.name.slice(doc.name.length - 3) === "mp4" ||
      doc.name.slice(doc.name.length - 3) === "mov" ||
      doc.name.slice(doc.name.length - 3) === "3gp" ||
      doc.name.slice(doc.name.length - 3) === "flv"
    ) {
      return (
        <FaPlayCircle
          onClick={() => getStreamAbleLink(doc.path_lower)}
          style={{ position: "absolute", top: "44%", left: "44%" }}
          size={50}
          color="white"
        />
      );
    } else return null;
  };
  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="container">
        <ul  className="text-center">
            { items.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <SortableListItem
                      axis="xy"
                      key={index}
                      index={index}
                      item={item}
                    />
                  </Fragment>
                );
              })}
        </ul>
      </div>
    );
  });
  const onRemoveTagElement = (e) => {
    var targetEle = e;
    if (!targetEle.id) {
      targetEle = e.target;
    }
    if (targetEle.id === "button_value") {
      const { name, className } = e.target;
      onRemoveTag(className, name);
    }
  };
  const onRemoveTagElementTitle = (e) => {
    var targetEle = e;
    if (!targetEle.id) {
      targetEle = e.target;
    }
    if (targetEle.id === "button_value") {
      const { name, className } = e.target;
      onRemoveTagTitle(className, name);
    }
  };
 const onRemoveTagElementDescription  = (e) => {
    var targetEle = e;
    if (!targetEle.id) {
      targetEle = e.target;
    }
    if (targetEle.id === "button_value") {
      const { name, className } = e.target;
      onRemoveTagDescription(className, name);
    }
  };
  const onEnterPress = (event, id) => {
    if (event.keyCode === 13) {
      onKeywordAdd(event.target.value, id);
      setInputValue("");
    }
  };
  const onEnterPressTitle = (event, id) => {
    if (event.keyCode === 13) {
      onKeywordAddTitle(event.target.value, id);
      setInputValueTitle("");
    }
  };
  const onEnterPressDescription = (event, id) => {
    if (event.keyCode === 13) {
      onKeywordAddDescription(event.target.value, id);
      setInputValueDescription("");
    }
  };
  let content = null;
  if (doc) {
    if (doc.streamLink) {
      content = (
        <Card className="m-4">
          <CardBody>
            <ReactPlayer width={"100%"} controls={true} url={doc.streamLink} />
          </CardBody>
        </Card>
      );
    } else {
      let element = getPlayIcon();
      content = (
        <Card className="m-4">
          <CardBody>
            {element}
            {doc.name.slice(doc.name.length - 3) === "pdf" ? (
              <FaFilePdf
                size="30%"
                style={{ color: "rgb(34, 130, 208)", marginRight: "10px" }}
              />
            ) : thumbnailUrl ? (
              <img
                className="video-size"
                width={"100%"}
                height={"100%"}
                src={thumbnailUrl}
                alt=""
              />
            ) : (
              <FaFile size="30%" className="folderIcon" />
            )}
          </CardBody>
        </Card>
      );
    }
    return (
      <Fragment>
        {doc[".tag"] === "file" ? (
          <Fragment>
            <Col sm={12} md={12} lg={6} xl={6}
              className="ml-5 justify-content-center align-self-center"
           
            >
              {content}
            </Col>
           <Col sm={12} md={12} lg={6} xl={6}>
              <Card className="m-4">
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={12} md={3} lg={3} xl={3}>
                        Original Clip Name
                      </Label>
                      <Col sm={12} md={9} lg={9} xl={9}>
                        <Input
                          id="exampleEmail"
                          name="filename"
                          value={doc.filename}
                          onChange={onFormFieldChange(doc.id)}
                          placeholder="with a placeholder"
                          type="name"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                    <Label for="exampleEmail" sm={12} md={3} lg={3} xl={3}>
                        Keyword
                      </Label>

                      <Col sm={12} md={9} lg={9} xl={9}>
                        <span className="count-span">
                          <label>Total Tags</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={doc.keywords && doc.keywords.length}
                          />
                        </span>

                        <div
                          className="form-outline form-black text-left "
                          style={{
                            border: "#ced4da 1px solid",
                            borderRadius: 6,
                          }}
                        >
                          <input
                            type="text"
                            style={{ outline: "none" }}
                            className="keyword-input"
                            placeholder="Enter Keyword"
                            value={inputValue} ///keyword
                            onKeyDown={(e) => onEnterPress(e, doc.id)}
                            onChange={(e) => setInputValue(e.target.value)}
                          />
                          {
                            <SortableList
                              axis={"xy"}
                              items={doc.keywords}
                              onSortEnd={onSortEnd}
                              onSortStart={() => onSortStart(doc.id)}
                              shouldCancelStart={(e) => onRemoveTagElement(e)}
                            />
                          }
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="mt-4" for="exampleText" sm={12} md={3} lg={3} xl={3}>
                        Description
                      </Label>
                      <Col sm={12} md={9} lg={9} xl={9}>
                        <span className="count-span">
                          <label>Characers</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={description_char ? description_char : 0}
                          />
                        </span>
                        <span className="count-span">
                          <label>Pharasees</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={description_phar ? description_phar : 0}
                          />
                        </span>
                        <Input
                          id="exampleText"
                          name="description"
                          type="textarea"
                          value={description}
                          onChange={onFormFieldChange(doc.id)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="mt-4" for="exampleFile" sm={12} md={3} lg={3} xl={3}>
                        Title
                      </Label>
                      <Col sm={12} md={9} lg={9} xl={9}>
                        <span className="count-span">
                          <label>Characers</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={title_char ? title_char : 0}
                          />
                        </span>
                        <span className="count-span">
                          <label>Pharasees</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={title_phar ? title_phar : 0}
                          />
                        </span>
                        <Input
                          id="exampleFile"
                          name="title"
                          type="text"
                          value={title}
                          onChange={onFormFieldChange(doc.id)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="mt-4" for="exampleText" sm={12} md={3} lg={3} xl={3}>
                        MA Description
                      </Label>
                      <Col sm={12} md={9} lg={9} xl={9}>
                      <span className="count-span">
                          <label>Total Tags</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={doc.ma_description && doc.ma_description.length}
                          />
                        </span>
                        <div
                          className="form-outline form-black text-left "
                          style={{
                            border: "#ced4da 1px solid",
                            borderRadius: 6,
                          }}
                        >
                          <input
                            type="text"
                            style={{ outline: "none" }}
                            className="keyword-input"
                            placeholder="Add MA-Description"
                            value={inputValueDescription}
                            onKeyDown={(e) => onEnterPressDescription(e, doc.id)}
                            onChange={(e) => setInputValueDescription(e.target.value)}
                          />
                          {
                            <SortableList
                              axis={"xy"}
                              items={doc.ma_description}
                              onSortEnd={onSortEnd}
                              onSortStart={() => onSortStart(doc.id)}
                              shouldCancelStart={(e) =>
                                onRemoveTagElementDescription(e)
                              }
                            />
                          }
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={12} md={3} lg={3} xl={3}>
                        MA Title
                      </Label>
                      <Col sm={12} md={9} lg={9} xl={9}>
                      <span className="count-span">
                          <label>Total Tags</label>{" "}
                          <input
                            disabled
                            className="count-input"
                            value={doc.ma_title && doc.ma_title.length}
                          />
                        </span>
                        <div
                          className="form-outline form-black text-left "
                          style={{
                            border: "#ced4da 1px solid",
                            borderRadius: 6,
                          }}
                        >
                          <input
                            type="text"
                            style={{ outline: "none" }}
                            className="keyword-input"
                            placeholder="Enter MA-Title"
                            value={inputValueTitle}
                            onKeyDown={(e) => onEnterPressTitle(e, doc.id)}
                            onChange={(e) => setInputValueTitle(e.target.value)}
                          />
                          {
                            <SortableList
                              axis={"xy"}
                              items={doc.ma_title}
                              onSortEnd={onSortEnd}
                              onSortStart={() => onSortStart(doc.id)}
                              shouldCancelStart={(e) =>
                                onRemoveTagElementTitle(e)
                              }
                            />
                          }
                        </div>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
};

export default VideoListing;
