import React, { useEffect, useState } from "react";
import { token$, updateToken } from "../store";
import Main from "../Main/Main";
import { Helmet } from "react-helmet";
import "./Home.css";
import { Dropbox } from "dropbox";
import Header from "../Header/Header";
import MemoFooter from "../Footer/Footer";
import topImage from "../Home/image/clouds.svg";
import "../Modals/Modals.css";
import { Redirect } from "react-router-dom";

const Home = ({ location }) => {
  const [localToken, updateLocalToken] = useState(token$.value);
  const [documents, updateDocs] = useState([]);
  const [search, updateSearch] = useState("");
  const [userName, updateUserName] = useState("");
  const filterSearch = (e) => {
    updateSearch(e.target.value);
  };

  useEffect(() => {
    const subscribe = token$.subscribe((token) => {    
      updateLocalToken(token);
    });

    return () => subscribe.unsubscribe();
  }, []);

  useEffect(() => {
    const dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    dropbox
      .usersGetCurrentAccount()
      .then(function (response) {
        updateUserName(response.name.given_name);
      })
      .catch(function (error) {
        console.error(error);
      });
    // dropbox
    //   .usersGetSpaceUsage()
    //   .then(function (response) {
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });
  }, [localToken]);

  const logOut = () => {
    updateToken(null);
  };

  if (!localToken) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="image-top">
        {/* <span className="imageTop-Span"></span> */}
        {/* <img className="imageTop" src={topImage} alt="background" /> */}
      </div>
      <div className="content-container">
        <Helmet>
          <title>KW@stockagent</title>
        </Helmet>
        <div className="header">
          <Header
            filterSearch={filterSearch}
            logOut={logOut}
            location={location}
            search={search}
            userName={userName}
          />
          
        </div>
        <div className="content">
          <div className="mainArea">
            <Main
              localToken={localToken}
              documents={documents}
              updateDocs={updateDocs}
              location={location}
              search={search}
            />
          </div>
        </div>
        <MemoFooter />
      </div>
    </>
  );
};

export default Home;
