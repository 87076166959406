import React from "react";
import "./Footer.css";

const Footer = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy;
  return (
    <footer>
      <p>
        copyright@stockagent-{today}
        <a href="https://www.freepik.com/free-photos-vectors/technology" ></a>
      </p>
    </footer>
  );
};

const MemoFooter = React.memo(Footer);

export default MemoFooter;
