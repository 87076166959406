import { BehaviorSubject } from 'rxjs';
// save the accesstoken to store so we can subscribe later when we want to use token
export const token$ = new BehaviorSubject(localStorage.getItem('token'));export function updateToken(token) {
	if (token) {
		localStorage.setItem('token', token);
		localStorage.setItem("sort_by", "list")
	} else {
		localStorage.removeItem('token');
		localStorage.removeItem("sort_by")
	}
	token$.next(token);
}
