/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FaFolder, FaFile, FaFilePdf } from "react-icons/fa";
import "./GridList.css";
import { Dropbox } from "dropbox";
import DetailModal from "../../Modals/DetailVideoModal";
import { truncate } from "../../common/_commonfunctions";
import { UncontrolledTooltip } from "reactstrap";
import Tooltip from "../../common/Tooltip/ToolTip";

const GridList = ({ doc, localToken }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(false);
  const [modalData, setModalData] = useState(doc);
  const [thumbnailUrl, updateThumbnailUrl] = useState(null);

  const nodeDropdown = useRef();

  useEffect(() => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    console.log("TRY TO FETCH THUMBNAIL");
    if (
      doc.name.slice(doc.name.length - 3) === "jpg" ||
      doc.name.slice(doc.name.length - 4) === "jpeg" ||
      doc.name.slice(doc.name.length - 3) === "png" ||
      doc.name.slice(doc.name.length - 3) === "mkv" ||
      doc.name.slice(doc.name.length - 3) === "mp4" ||
      doc.name.slice(doc.name.length - 3) === "mov" ||
      doc.name.slice(doc.name.length - 3) === "3gp" ||
      doc.name.slice(doc.name.length - 3) === "flv"
    ) {
      dropbox
        .filesGetThumbnail({
          path: doc.path_lower,
          size: "w128h128",
        })
        .then((response) => {
          if (response.fileBlob) {
            const url = URL.createObjectURL(response.fileBlob);
            updateThumbnailUrl(url);
          }
          console.log("RENDER from THUMBNAIL");
        })
        .catch(function (error) {
          console.log(error, "Error by creating thumbnail");
        });
    }
  }, [doc.name, updateThumbnailUrl, doc.path_lower, localToken]);

  const onItemClicked = (e) => {
    let dropbox = new Dropbox({ fetch: fetch, accessToken: localToken });
    dropbox
      .filesGetThumbnail({
        path: e.path_lower,
        size: "w2048h1536",
      })
      .then((response) => {
        if (response.fileBlob) {
          const url = URL.createObjectURL(response.fileBlob);
          setVideoThumbnail(url);
        }
      })
      .catch(function (error) {
        console.log(error, "Error by creating thumbnail");
      });
    setModalData(e);
    updateIsOpen(!isOpen);
  };

  if (doc) {
    return (
      <li className="wrapper-grid">
        <div className="itemSmlCtn">
          {doc[".tag"] === "file" ? (
            <>
              {doc.name.slice(doc.name.length - 3) === "pdf" ? (
                <FaFilePdf
                  size="4rem"
                  style={{ color: "rgb(34, 130, 208)", marginRight: "10px" }}
                />
              ) : thumbnailUrl ? (
                <img
                  src={thumbnailUrl}
                  alt="thumbnail"
                  onClick={() => onItemClicked(doc)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <FaFile size="4rem" className="folderIcon" />
              )}
              {/* <a
                className="documentLink"
                onClick={() => getLinkToFile(doc.path_lower)}
              >
                {doc.name}
              </a> */}
            </>
          ) : (
            <>
              <Link to={"/home" + doc.path_lower} className="documentLink">
                <FaFolder size="4rem" className="folderIcon" />
              </Link>
            </>
          )}
        </div>
        <div className="dropDownCtn" ref={nodeDropdown}>
          <Tooltip content={doc.name.length>20 ? doc.name : ""} direction="bottom">
            <p className="m-0"> {truncate(doc.name)}</p>
          </Tooltip>
        </div>
        <DetailModal
          isOpen={isOpen}
          modalData={modalData}
          thumbnailUrl={videoThumbnail}
          accessToken={localToken}
          toggle={() => updateIsOpen(!isOpen)}
        />
      </li>
    );
  }
};

export default GridList;
