export const TagsList = [
  "slow motion",
  "fashion",
  "afro hair",
  "headphones",
  "music",
  "city life",
  "urban",
  "cool",
  "walking",
  "street",
  "smiling",
  "big hair",
];
export const MA_Title_List = [];
export const MA_Description_List = [];

export function countWords(text) {
  let removeChar = text.replace(/[^A-Za-z]\s+/g, " ");

  let newWord = removeChar.trim().split(" ");
  return newWord.length;
}
